html.dark {
  background-color: rgb(15, 23, 42);
  color: white;
}

.container_my {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  padding-top: 6%;
  min-height: 100vh;
  background-color: blue;
  padding-bottom: 6%;
  margin-right: 0;
  background-size: cover;
  background-repeat: repeat-x;
}

.header_title {
  font-weight: 600;
  font-size: 50px;
  flex: 0.75;
  font-family: 'Merriweather', serif;
  color: #3b7ea1;
}

.container_my .timer {
  color: #3b7ea1;
  flex: 0.5;
  font-size: 20px;
}

.container_my .icons {
  display: flex;
  flex: 1;
  width: 30%;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 1%;
  margin-bottom: 1%;
}

.container_my .icons svg {
  height: 3vh;
  width: 3vh;
  color: #c4820e;
}

.container_my .grid {
  flex: 5;
}

.container_my .keyboard {
  flex: 5;
  max-width: 90%;
}

.container_my .key {
  border-color: black;
  border-style: solid;
  border-width: 1px;
  font-size: 17px;
}

#link {
  color: #3b7ea1;
  font-weight: bold;
}
